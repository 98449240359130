@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}


body {
  margin: 0;
}


main {
  display: block;
}


h1 {
  margin: 0.67em 0;
  font-size: 2em;
}


hr {
  overflow: visible; /* 2 */
  height: 0; /* 1 */
  box-sizing: content-box; /* 1 */
}


pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}


a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}


code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}


small {
  font-size: 80%;
}


sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}


img {
  border-style: none;
}

/* Forms
   ========================================================================== */


button,
input,
optgroup,
select,
textarea {
  margin: 0; /* 2 */
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
}


button,
input {
  /* 1 */
  overflow: visible;
}


button,
select {
  /* 1 */
  text-transform: none;
}


button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}


button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}


fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  display: table; /* 1 */
  padding: 0; /* 3 */
  max-width: 100%; /* 1 */
  box-sizing: border-box; /* 1 */
  white-space: normal; /* 1 */
  color: inherit; /* 2 */
}


progress {
  vertical-align: baseline;
}


textarea {
  overflow: auto;
}


[type="checkbox"],
[type="radio"] {
  padding: 0; /* 2 */
  box-sizing: border-box; /* 1 */
}


[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}


[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}


[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}


template {
  display: none;
}

[hidden] {
  display: none;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  display: -ms-grid;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: Mulish, sans-serif;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.wrapper {
  margin: 3rem;
  border: 1px solid #5cc0c9;
  border-radius: 30px;
  padding: 5rem 0;
  width: clamp(100px, 90vw, 1200px);
  max-height: 700px;
  text-align: center;
  background-image: url(/public/background-logo.svg),
    url(/public/background-image.png);
  background-position: top 2rem left 2rem, top -5px right -5px;
  background-size: 225px, 200px;
  background-repeat: no-repeat, no-repeat;
}
.response {
  color: #5cc0c9;
  display: flex;
  justify-content: center;
  height: 2rem;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

form {
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;
  text-align: center;
  background: #fff;
}

h1 {
  margin-bottom: 3rem;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 1;
}

#user {
  background-image: url("/public/icon-user.svg");
  background-position: center left 1rem;
  background-repeat: no-repeat;
}

#password {
  background-image: url("/public/icon-password.svg");
  background-position: center left 1rem;
  background-repeat: no-repeat;
}

input {
  margin-bottom: 1.5rem;
  border: 1px solid #2f8c9e;
  border-radius: 5px;
  padding: 1rem 1rem 1rem 2.5rem;
  width: 100%;
}

button {
  margin-bottom: 1rem;
  border: none;
  border-radius: 99px;
  padding: 1rem;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background: #5cc0c9;
  cursor: pointer;
}
.link-button {
  text-decoration: none;
  border: none;
  border-radius: 99px;
  padding: 1rem;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background: #5cc0c9;
  cursor: pointer;
}
.link:focus {
  outline: 2px dotted #5cc0c9;
  outline-offset: 2px;
}
input:focus,
button:focus {
  outline: 2px dotted #5cc0c9;
  outline-offset: 2px;
}

input:focus {
  outline-offset: -4px;
}

@media (max-width: 1023px) {
  html {
    font-size: 14px;
  }

.wrapper {
    background-position: top 1.5rem left 1.5rem, top -5px right -5px;
    background-size: 150px, 150px;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 12px;
  }

  .wrapper {
    padding: 10rem 2rem 2.5rem;
    background-image: url(/public/background-logo.svg);
    background-position: top 1.5rem center;
    background-size: 200px;
  }

  h1 {
    font-size: 1.5rem;
  }

  input {
    padding: 1rem 1rem 1rem 3rem;
  }
}